import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
 import useStyles from '../styles/styles';
import "./header.css";
import "./index.css";
const Hero = () => {
  const classes = useStyles();

  return (
    <div id="homepage">  
    <Box  className={"bgheader"} >
      
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={7}>
          <Typography variant="h3"  style={{color:"white"}}   fontWeight={700} className={classes.title}>
             One Stop Spot For AI Tools
          </Typography>
          <Typography variant="h6" style={{color:"white"}} className={classes.subtitle}>
          AI marketing can help you deliver personalized messages to customers at appropriate points in the consumer lifecycle.With over 5years experience in Software fields and Delivery, we are your best client.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px' ,marginRight:"20px"}}
          >
            Read more
          </Button>
        </Grid>
        
      </Grid>
    </Box>
    </div>
  );
};

export default Hero;