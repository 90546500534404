import Hero from "./components/Hero"
import Header from "./components/Header"
import Section from "./components/Section"
import Testimonial from "./components/Testimonial"
import ContactUs from "./components/ContactUs"
import Footer from "./components/Footer"
import AboutUs from "./components/AboutUs"
 
import '@fontsource/noto-sans-tc/300.css';
import '@fontsource/noto-sans-tc/400.css';
import '@fontsource/noto-sans-tc/500.css';
import '@fontsource/noto-sans-tc/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { Box, Fab } from "@mui/material"
import AssistantIcon from '@mui/icons-material/Assistant'; 
import { makeStyles, withStyles } from '@mui/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans',
       
    ].join(','),
  },});

  const style = () => ({
    fab: {
      float:"left",
       
    }
  });
function landingpage() {
  const classes = style();

  return (
    <Box style={{backgroundColor:"#F0F9F6"}}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      
      <Hero />
      <Section /> 
      <AboutUs />
     
      <Testimonial />
      <ContactUs />
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2)
        }}
        color="primary"
      >
        <AssistantIcon />
      </Fab>
      <Footer />
      </ThemeProvider>
    </Box>

  );
}

export default withStyles(style) (landingpage);
