import {
  AppBar,
  Typography,
   Link,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'; 
import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from '../styles/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {  RiLoginCircleLine } from "react-icons/ri";
import { FcAbout ,FcServices } from "react-icons/fc";
import { FaBlogger } from "react-icons/fa6";
import { FcBusinessContact } from "react-icons/fc";
function ElevationScroll(props) {
  const { children, window } = props;
   
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
 
  window: PropTypes.func,
};

const Header = (props) => {
  const classes = useStyles();
  const links = [
   
    { id: 1, route: 'About us', url: '#aboutpage' ,icon:<FcAbout size={25}/>},
    { id: 2, route: 'Service', url: '#servicepage',icon:<FcServices size={25}/> },

    { id: 3, route: 'Contact us', url: '#contactus',icon:<FcBusinessContact size={25}/> },
    { id: 4, route: 'Blog', url: '#blogpage' ,icon:<FaBlogger size={25}/>},
    { id: 5, route: 'Login', url: '#  ',icon:<RiLoginCircleLine size={25}/> },
  ];

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

  
      <List>
        {links.map((link) => (
          <Link href={link.url} style={{
              color:"black",
            textDecoration: 'none' }}  >
<ListItem key={link.id}    >
<ListItemButton>
    <ListItemIcon>
        {link.icon}
    </ListItemIcon>
    <ListItemText primary={link.route} />
</ListItemButton>
</ListItem>
</Link>
        ))}
      </List>
    </Box>
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <ElevationScroll {...props}>
        <AppBar sx={{ bgcolor: "green" }}>
          <Toolbar className={classes.toolBar}>
            <Link href="#" style={{ textDecoration: 'none' }}>
              <Typography  variant="h5" className={classes.logo}>
               AI Spots
              </Typography>
            </Link>

            {matches ? (
              <Box>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer('right', true)}
              >
                <MenuIcon className={classes.menuIcon} style={{color:"white"}} />
              </IconButton>

              <Drawer
                anchor="right"
                open={state['right']}
                onClose={toggleDrawer('right', false)}
              >
                {list('right')}
              </Drawer>
            </Box>
            ): <Box
              sx={{
                display: 'flex',
                  justifyContent: 'space-between',
                flexGrow: '0.1',
              }}
            >
              {links.map((link) => (
                <Link href={link.url}  style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap', 
                  textDecoration: 'none' }} key={link.id}>
               
                  <Typography  component="span"  style={{marginLeft:"20px"}} className={classes.link}> 
                  
                 
                  {link.route } </Typography>
                </Link>
              ))}
            </Box>}
           
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  );
};

export default Header;