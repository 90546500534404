import React from 'react';
import { Typography, Box, Card, CardContent, Grid, IconButton, CardActions, CardHeader, CardMedia, Collapse, Button } from '@mui/material';
 
import useStyles from '../styles/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExpandMore } from '@mui/icons-material';
import "./index.css"
const Testimonial = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState({});
   
  const handleExpandClick = (id) => {
    const expended =  {};
    expended[id] = expended.hasOwnProperty(id) ? !expended[id] : true
    setExpanded(expended);
    
  }
  const handleClose=()=>{
    
    setExpanded({});
    
  }
  
  const reviews = [
    {
      id: 1,
      name: 'OpenAI', 
      date:"Jun 24 ,2023",
      statement:" OpenAI is a non-profit research group of industry experts. It aims to not only bring the power of AI to the masses, but also to make it accessible.",      image_url:
        'https://www.dexerto.com/cdn-cgi/image/width=3840,quality=75,format=auto/https://editors.dexerto.com/wp-content/uploads/2023/03/14/OpenAI-GPT-4.jpg',
      position: 'Software Engineer at Kadex',
    },
    {
      id: 2,
      
      name: 'Machine Learning',
      date:"Jun 26 ,2023",
      statement:  " As a ML developer, Jason aims to provide personal education to the reader, with tips and tools he has picked up during the AI journey.",
      image_url:'https://images.squarespace-cdn.com/content/v1/5daddb33ee92bf44231c2fef/e163d977-3fe5-42da-b959-5b5319027458/machine-learning-in-healthcare.jpg?format=1500w',
      position: 'Product Manager at Google',
    },
    {
      id: 3,
      name: 'Google AI',
      date:"Jun 29 ,2023",
      statement:"Google is a relatively new internet start-up. Google also runs a great AI blog run by the Google AI research team.",
      image_url:
        'https://indepthseo.com/wp-content/uploads/2022/04/Google-AI-will-Update-Business-Information-Automatically.jpg',
      position: 'Founder of Crypto',
    },
  ];
  return (
    <div id="blogpage" style={{margin: '40px 0px '}}>
    <Typography variant='h4' style={{textAlign:"center"}}>Blog post</Typography>
    
    <Box
      sx={{
        flexGrow: 1,
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
       
      }}
      
    >
      
      <Grid container spacing={1} style={{marginLeft:"20px"}}>
        {reviews.map((review) => (
          <Grid item sm={12} md={4} key={review.id}>
              <Card sx={{ maxWidth: 345 }} style={{padding:"10px"}} elevation={3}>
      <CardHeader
          action={
            <ExpandMore
            expand={expanded}
            onClick={() => handleExpandClick(review.id)}
       
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          }
      
        title={review.name}
        subheader={review.date}
      />
      <CardMedia
        component="img"
        height="194"
         className={classes.image}
        image={review.image_url}
        alt="Paella dish"
      />
   
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
       
      </CardActions>
      <Collapse in={expanded[review.id]} timeout="auto" unmountOnExit>
        <CardContent>
        
          <Typography paragraph>
             {review.statement}
          </Typography>
          
        </CardContent>
        <CardActions sx={{float:"right"}}>
          <KeyboardArrowUpIcon onClick={handleClose}/> 
        </CardActions>
      </Collapse>
    </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </div>
  );
};

export default Testimonial;