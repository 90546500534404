import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,

  Button,
} from '@mui/material';
import useStyles from '../styles/styles';
import "./index.css"
const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const submitForm = (e) => {
    e.preventDefault();
    console.log({ email, firstName, subject, message });
  };

  return (
    <div id="contactus">
    <Box className={classes.formContainer} >
      <Typography variant="h4" className={classes.formHeading}>
        Contact Us
      </Typography>
      <Box
        className={classes.form}
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Full Name"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          label="Subject"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
<TextField
     placeholder="Enter a message"
     multiline
  rows={2}
  className={classes.textArea}
  value={message} 
  maxRows={4}
  onChange={(e) => setMessage(e.target.value)}
/>
         

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ width: '200px', fontSize: '16px',marginTop:"20px" }}
          onClick={submitForm}
        >
          Submit
        </Button>
      </Box>
    </Box>
    </div>
  );
};

export default ContactUs;