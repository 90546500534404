import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import section from '../images/section.jpg';
import useStyles from '../styles/styles';
import "./index.css"
const AboutUs = () => {
  const classes = useStyles();

  return (
    <div id="aboutpage" >   
    <Box className={classes.aboutUsContainer} >
    
      <Grid container spacing={6} className={classes.gridContainer} >
        <Grid item xs={12} md={5}>
          <img src={section}  alt="My Team" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            We build a Massive AI Platform
          </Typography>
          <Typography className={classes.aboutUsSubtitle} style={{ marginTop:"15px"}} >
            Your needs to be in safe hands at all times with our services. We are trusted by
            over 100+ companies to deliver quality marketing campaigns using
            Artificial intelligence and Software developement .
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px' }}
          >
          About us
          </Button>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

export default AboutUs;