import React from 'react';
import { Box, Typography, Link, Container, Grid } from '@mui/material';
 import { Facebook, Twitter } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Footer = () => {
 
  return (
    <Box
    component="footer"
    sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === "light"
          ? "#FFFFFF"
          :"#FFFFFF",
      p: 6,
  
    }}
  >
    <Container maxWidth="lg">
      <Grid container spacing={5}>
        
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            For Contact
          </Typography>
          <Typography variant="body2" color="text.secondary">
          56, Ranga Pillai St, MG Road Area, Puducherry, 605001
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Email: actechsoft@gmail.com
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Phone:  9600752446
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Follow Us
          </Typography>
          <Link href="https://www.facebook.com/" color="inherit">
            <Facebook />
          </Link>
          <Link
            href="https://www.instagram.com/"
            color="inherit"
            sx={{ pl: 1, pr: 1 }}
          >
            <LinkedInIcon />
          </Link>
          <Link href="https://www.twitter.com/" color="inherit">
            <Twitter />
          </Link>
        </Grid>
      </Grid>
      
    </Container>
  </Box>
  );
};

export default Footer;