import React from 'react';
import { Grid, Typography, Box, Card, CardContent, CardMedia, Avatar, CardActions, Link } from '@mui/material';
import "./index.css";
import useStyles from '../styles/styles';
import health from "../images/health.png";
import education from "../images/edu.png";
import addition from "../images/addition.png";
const Section = () => {
  const classes = useStyles();

  const sectionItems = [
    {
      id: 1,
      img: health ,
      heading:"Health & Fitness",
      sentence:
        'Stay Healthy and Be Fit with AI as your fitness Buddy',
        link:"https://www.mikeai.co"
    },
    {
      id: 2,
      img: education,
      heading:"Education",
      sentence:
        'Learning made easy and reachable to all with AI',
        link:"#"
    },
    {
      id: 3,
      img:  addition,
      heading:"Human Resource",
      sentence: 'Find any suitable resource for your business with the power of AI',
      link:"#"
    },
  ];
  return (
    <div id="servicepage">
    <Box sx={{ flexGrow: 1, minHeight: '400px' }} >
      <Typography style={{ textAlign: "center" }} variant="h4" >Service</Typography>


      <Box
      sx={{
        flexGrow: 1,
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
      }}
      id="blogPage"
    >
      
      <Grid container spacing={2}>
        {sectionItems.map((review) => (
           
            
          <Grid item sm={12} md={4} key={review.id}>
             <Link style={{textDecoration:"none"}} href={review.link}  target="_blank" > 
            <Card className={classes.testimonialCard}>
              <CardContent>
                
                <Box sx={{ display: 'flex' }}>
                  <Avatar
                    src={review.img}
                    size="large"
                    style={{width:"100px",height:"100px"}}
                    className={classes.avatar}
                  />
                  <Box>
                    <Typography variant={"h5"}>{review.heading}</Typography>
                    <Typography variant={"subtitle1"} className={classes.testimonialPosition}>
                      {review.sentence}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <CardActions>
              
              </CardActions>
            </Card>
            </Link>
          </Grid>
       
             
        ))}
      </Grid>
    </Box>




{/* 
     <Box className={classes.sectionGridContainer} >
      <Grid container spacing={2}  >
          {sectionItems.map((section) => (
            <Grid item xs={6} md={4}>

             <Card sx={{ display: 'flex' }} elevation={4}>
            <Box sx={{ display: 'flex', flexDirection: 'row',padding:"10px" }}>

              <CardMedia
                component="img"
                sx={{ width: 100 }}
                image={section.img}
                alt="Live from space album cover"
              />
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  {section.heading}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" style={{textAlign:"center"}} component="div">
                  {section.sentence}
                </Typography>
              </CardContent>

            </Box>
            </Card>
            
       </Grid>
          ))}

         
      </Grid>
      </Box> */}
    </Box>
    </div>
  );
};

export default Section;